import React from "react"
import { Row, Col, Container } from "reactstrap"
import Layout from "../components/Layout"
import HelmetTitle from "../components/utils"
import webAppImage from "../images/web-app-people.png"
import "../scss/modules/_m-hero-web-app.scss"
import "../scss/app.scss"

const WebApp = () => {
  const title = "Web Application",
    tagline = `Off-the-shelf applications may not have all the bells and whistles you need to move your business 
      into the future. When you utilize our customer-first custom web application development services, you are 
      getting an application that's designed to specifically meet your needs. Hire us to help you build an 
      excellent application.`

  return (
    <Layout>
      <div className="web-app mb-6">
        <HelmetTitle title="Web Application" />
        <div className="web-app-banner pt-6 pb-6 text-white text-justify">
          <Container fluid>
            <Row>
              <Col xl={{ size: 10, offset: 1 }}>
                <h1 className="mb-5 font-weight-bold">{title} </h1>
                <h3 className="mb-5 text-justify">{tagline}</h3>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="py-4 grey-background" fluid>
          <Row>
            <Col
              xl={{ size: 4, offset: 1 }}
              md={{ size: 4, offset: 0 }}
              sm={{ size: 4, offset: 2 }}
              xs={{ offset: 1 }}
              className="web-app-img-col"
            >
              <img src={webAppImage} alt="Web Application Building" className="img-responsive web-app-img" />
            </Col>

            <Col
              xl={{ size: 6, offset: 0 }}
              lg={{ size: 6, offset: 1 }}
              md={{ size: 6, offset: 2 }}
              sm={{ size: 12, offset: 0 }}
              xs={{ offset: 0 }}
            >
              <h4 className="font-weight-bold web-app-header mt-2 mb-4">
                Next generation Web Applications to help you sail smooth through challenging and constantly-evolving
                technological changes.
              </h4>
              <p>
                A winning web application requires attractive design, well-written content, strong and scalable
                architecture, good user experience, resistance to cyber attacks, etc. At Foretheta, we take care of
                every piece when building software and we always give added value through our recommendations that stem
                from our several years of experience building web applications.
              </p>
              <p>
                On top of that, transparency is a core value that anyone should expect when working with Foretheta. We
                are proud to be very transparent in all levels; schedule estimation, financial offer, planning,
                technical implementation and challenges, and overall progress. Is that what you look for in your next
                project? <a href="mailto:help@foretheta.com">Hire us</a>
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="my-4" fluid>
          <Row noGutters>
            <Col md={{ size: 10, offset: 1 }}>
              <h4 className="text-center services-header my-4 font-weight-bold">Our web application services</h4>
              <p className="text-center">
                We offer customized applications, consulting and technical services for critical technology initiatives.
                Software demands vary, and our focus is to understand the business model of each product we deal with in
                order to be "ahead of the curve" in a very competitive market. Many competencies are often required to
                deliver an excellent product, that's why we always sharpen the skills of our team in various areas
                including, but not limited to:
              </p>
            </Col>
          </Row>
        </Container>
        <Row className="mt-5 web-app-row w-100 px-lg-0 pl-md-5 pl-sm-3 px-3" noGutters>
          <Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 0 }} sm={5}>
            <p className="services">Web and mobile development</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col lg={3} md={{ size: 3, offset: 0 }} sm={{ size: 5, offset: 1 }} className="mx-lg-4 mx-md-5">
            <p className="services">Software Engineering</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col md={3} sm={5}>
            <p className="services">QA & Testing</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 0 }} sm={{ size: 5, offset: 1 }}>
            <p className="services">Project & Product Management</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col md={3} sm={5} className="mx-lg-4 mx-md-5">
            <p className="services">E-commerce development</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col md={{ size: 3, offset: 0 }} sm={{ size: 5, offset: 1 }}>
            <p className="services">UI/UX design</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 0 }} sm={5}>
            <p className="services">Database Administration</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col md={3} sm={{ size: 5, offset: 1 }} className="mx-lg-4 mx-md-5">
            <p className="services">DevOps Engineering</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col md={3} sm={5}>
            <p className="services">Enterprise Resource Planning</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 0 }} sm={{ size: 5, offset: 1 }}>
            <p className="services">Business Analysis</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col md={3} sm={5} className="mx-lg-4 mx-md-5">
            <p className="services">Data Modeling</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
          <Col md={{ size: 3, offset: 0 }} sm={{ size: 5, offset: 1 }}>
            <p className="services">Systems Integration</p>
            <div className="line mb-4 mt-1"></div>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default WebApp
